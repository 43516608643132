export const environment = {
  production: true,
  isUnderMaintenance: false,
  isUnderMaintenanceFirebaseKey: 'BOMaintenanceDev',

  landingUrl: 'https://landing.dev.blacktesting.com.ar/#/',
  apiAGUrl: 'https://services.blacktesting.com.ar/dev',
  billboardAGUrl: 'https://services.blacktesting.com.ar/dev',
  termsAndConditions: 'https://billboard.dev.blacktesting.com.ar/terms-and-conditions',
  privacyPolicies: 'https://billboard.dev.blacktesting.com.ar/privacy-policies',

  apiUrl: 'https://api.dev.blacktesting.com.ar/api',

  // S3AWS
  mediaUrl: 'https://media.dev.blacktesting.com.ar',
  prefixBucketImages: 'development',
  prefixBucketFiles: 'development',
  bucketName: 'blackid-development-media',
  regionS3: 'us-east-1',

  // Cognito AWS
  identityPoolId: 'us-east-1:0d63217e-29b2-45ed-ba65-b0b979f09072',
  regionAws: 'us-east-1',
  userPoolId: 'us-east-1_I7wmvaFMn',
  userPoolWebClientId: '6orkm2n0fcl0n773cbkipdbm3c',

  emailSupport: 'soporte@blackid.app',

  maxTablePrice: 1000000,
  maxAccessPrice: 1000000,
  maxConsumerCredit: 1000000,
  maxNavbarContactName: 50,
  maxNavbarContactEmail: 50,
  maxValueDiscount: 100,
  maxCommerceNameLength: 50,
  maxCommerceDescriptionLength: 255,
  minPasswordLength: 6,
  maxPasswordLength: 30,
  maxCapacityWaitingList: 200,
  maxCapacityPerTable: 50,
  maxTableNumber: 10000,
  maxPeopleAdmittedPerHour: 1000,
  maxPeoplePerBooking: 1000,
  maxDescriptionShiftLength: 50,
  maxDniLength: 20,
  minDniLength: 7,
  maxPeopleNameLength: 30,
  maxPeopleLastNameLength: 30,
  maxOrderPrepareEstimatedTime: 180,
  maxReasonCancelationLength: 120,
  maxCreditOrderTable: 20000,
  maxTicketNameLength: 20,
  maxTicketDescriptionLength: 120,
  maxTableReferenceLength: 30,
  maxTableEventNameLength: 20,
  maxCapacityPerTableOnEvent: 500,
  maxNameEventLength: 50,
  maxEventDescriptionLength: 1000,
  maxPhoneLength: 12,
  maxUsernameLength: 30,
  maxNotesOnBookingLength: 120,
  maxCategoryNameLength: 30,
  maxCategoryDescriptionLength: 50,
  maxProductNameLength: 50,
  maxProductDescriptionLength: 180,
  maxProductPrice: 1000000,
  maxAttributeNameLength: 30,
  maxAttributeDescriptionLength: 120,
  maxPriceAtributte: 5000,
  imageFormat: 'jpeg',
  maxPromotionNameLength: 20,
  maxPromotionCodeLength: 8,
  maxTableAndListNotesLength: 50,
  limitLockSidebar: 1462,
  limitGuestOnTable: 50,
  maxQuantityTickets: 100000,
  maintenanceKey: 'BOMaintenanceDev',
  passwordRegex: '(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{8,32}',
  passwordBidAccessRegex: '[a-z0-9_-]{6,18}',
  showAlertVersionMinBidAccess: true,

  firebase: {
    apiKey: 'AIzaSyAFzi1hWZR3FEjqXTxYIRXS44AdmNFdR9s',
    authDomain: 'blackid-1b732.firebaseapp.com',
    databaseURL: 'https://blackid-1b732.firebaseio.com',
    projectId: 'blackid-1b732',
    storageBucket: 'blackid-1b732.appspot.com',
    messagingSenderId: '356588914077',
    appId: '1:356588914077:web:ef3b53d95f6b5c50525b74',
    measurementId: 'G-8VK1L30FG8'
  }
};
